details {
  margin: 20px 0;
  padding: 0 20px;
  border-radius: var(--rounded);
  background: var(--theme-light);
  border: solid 1px var(--light-gray);
  text-align: left;
  overflow: hidden;
}

summary {
  list-style: none;
  margin: 0 -20px;
  padding: 10px;
  line-height: var(--compact);
  transition: background var(--transition);
  cursor: pointer;
}

summary:hover {
  background: var(--light-gray);
}

details[open] summary {
  background: var(--light-gray);
}

summary::before {
  content: "❯";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  width: 1em;
  height: 1em;
}

details[open] summary::before {
  transform: rotate(90deg);
}
